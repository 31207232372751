.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 50px;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-title {
    margin-top: 2rem;
    font-family: 'Avenir';
    border-bottom: 5px solid #ee3124;
    letter-spacing: 0.5rem;
    margin-bottom: 0 !important;
    text-align: center;
}

.login-subtitle {
    margin-top: 0.2em !important;
    letter-spacing: 0.2rem;
    text-align: center;
}

.login-button {
    margin-top: 2rem;
}
@primary-color: #ee3124;
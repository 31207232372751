.action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.action-header {
    font-size: 24px;
}

.tax-header-container {
    background-color: #fff;
    padding: 25px 50px;
}
@primary-color: #ee3124;
.card-chartTop {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .card-hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 16%), 
      0 3px 6px 0 rgba(0, 0, 0, 12%), 0 5px 12px 4px rgba(0, 0, 0, 9%);
    padding: 16px 20px 8px;
  }
  .card-metaWrap {
    float: left;
  }
  
  .card-index-wrap {
    height: 22px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }
  
  .card-index-action {
    position: absolute;
    top: 4px;
    right: 0;
    line-height: 1;
    cursor: pointer;
  }
  
  .card-index-total {
    height: 38px;
    margin-top: 4px;
    margin-bottom: 0;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    text-align: left;
  }
  
  .card-index-footer {
    margin-top: 8px;
    padding-top: 9px;
    border-top: 1px solid #f0f0f0;
    text-align: left;
  }
  
@primary-color: #ee3124;
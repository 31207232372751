.main-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 16%), 
      0 3px 6px 0 rgba(0, 0, 0, 12%), 0 5px 12px 4px rgba(0, 0, 0, 9%);
}

.main-layout-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 1000;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 16%), 
      0 3px 6px 0 rgba(0, 0, 0, 12%), 0 5px 12px 4px rgba(0, 0, 0, 9%);
}

.main-layout {
    margin-top: 48px;
    margin-left: 250px;
    margin-bottom: 48px;
}

.main-layout-content {
    padding: 16px 50px;
    height: 100%;
}

.main-layout-footer {
    display: flex;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}
@primary-color: #ee3124;